<template>
  <div>
    <div class="flix-form-group">
      <div class="flix-row">
        <div class="flix-col-md-12">
          <div class="flix-form-group">
            <router-link class="flix-html-a flix-text-danger" :to="{name: 'dashboardCredits'}"><flixIcon :id="'warning-sign'" /> {{ $t('message.creditsForSms') }}</router-link>
          </div>
        </div>
        <div class="flix-col-md-3">
          <div class="flix-form-group">
            <select class="flix-form-control" v-model="data[1].format">
              <option value="email">{{ $t('reminder.email') }}</option>
              <option v-if="sms" value="tel">{{ $t('reminder.tel') }}</option>
            </select>
          </div>
        </div>
        <div class="flix-col-md-3">
          <div class="flix-form-group">
            <select class="flix-form-control" v-model="data[1].trigger_int">
              <option v-for="index in Array(23).keys()" :key="index" :value="index + 1">{{ index + 1 }}</option>
            </select>
          </div>
        </div>
        <div class="flix-col-md-3">
          <div class="flix-form-group">
            <select class="flix-form-control" v-model="data[1].trigger_format">
              <option value="days">{{ $t('message.days') }}</option>
              <option value="hours">{{ $t('message.hours') }}</option>
            </select>
          </div>
        </div>
        <div class="flix-col-md-3">
          <div class="flix-form-group">
            <span class="flix-form-control">{{ $t('message.beforeAppointmentStarts') }}</span>
          </div>
        </div>
        <div class="flix-col-md-12">
          <div class="flix-form-group">
            <select class="flix-form-control" v-model="data[1].status">
              <option value="active">{{ $t('message.active') }}</option>
              <option value="inactive">{{ $t('message.inactive') }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <editTxt :data="data[1]" />
    <saveBtn :onClick="function(){ onClose() }">
      <template v-slot:text>
        {{ $t('message.save', { name: $tc('message.reminder', 1) }) }}
      </template>
    </saveBtn>
    <div class="flix-pull-right">
      <a href="#" @click.prevent="onRemove" class="flix-text-danger">{{ $t("message.delete") }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    editTxt () { return import('@/components/reminder/editText') }
  },
  props: {
    data: Array,
    onClose: Function,
    onRemove: Function
  },
  data () {
    return {
      edit: false,
      variables: this.$getUserVariables(),
      sms: false
    }
  },
  methods: {
    getCheckBookingForm () {
      this.$store.getters.assistents.forEach(function (assistent) {
        if (assistent.ID === this.data[1].to) {
          this.$flix_post({
            url: 'assistent/get_data',
            data: { user: this.variables.user.md5_id, ID: assistent.ID },
            callback: function (ret) {
              if (!ret.data[0]) {
                return false
              }
              ret.data[1].form.forEach(function (elem) {
                if (elem.subtype === 'tel') {
                  this.sms = true
                }
              }.bind(this))
            }.bind(this)
          })
        }
      }.bind(this))
    },
    getCheckSMS () {
      if (!this.variables.user.first_name) {
        return false
      }
      if (this.variables.user.sms * 1 <= 0) {
        return false
      }
      this.getCheckBookingForm()
    }
  },
  mounted () {
    this.getCheckSMS()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
